<template>
      <span> {{ timeSlotRendered }} </span>
</template>

<script>
export default {
  name: "TimeRender",
  props: ['timeSlot'],
  data(){
      return{
        timeSlotRendered: ""
      }
  },
  methods:{
  },
  mounted(){
    this.timeSlotRendered = this.timeSlot;
    try{
      this.timeSlotRendered = new Date(
          "1970-01-01T" + this.timeSlot.split("-")[0] + "Z"
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        }) +
        " - " +
        new Date(
          "1970-01-01T" + this.timeSlot.split("-")[1] + "Z"
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
      });
    }
    catch(e)
    {
      this.timeSlotRendered = this.timeSlot;
    }
  }
}
</script>
<style>
</style>
