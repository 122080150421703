<template>
    <div>
      <span> {{ dateVal | moment("Do MMM YYYY") }} </span>
    </div>
</template>

<script>

export default {
  name: "DateRenderer",
   props: ['dateVal'],
  data(){
      return{
        
      }
  },
  methods:{

},
mounted(){
}
}

</script>

<style>

</style>
